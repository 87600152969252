.title {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font-size: 2rem;
    color: inherit;
    cursor: pointer;
}
li.k-drawer-item.k-disabled {
    display: none;
}

.title:focus {
    outline: none;
}

.spacer {
    width: auto;
}

button.k-button.k-button-md.k-button-solid.k-button-solid-base.k-icon-button.k-input-button {
    display: none;
}

span.k-input-value-text {
    text-align: center;
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
    .title {
        font-size: 1.35em;
    }
    .menu{
        display:flex;
    }
}