.autosize-div {
    display: inline-block;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    margin-top: 5%;
}
.k-upload-dropzone{
    font-size: 0;
}

button#html5-qrcode-button-camera-start {
    height: 50px;
    width: 150px;
}
button#html5-qrcode-button-camera-stop {
    width: 150px;
    height: 50px;
}

button#html5-qrcode-button-camera-permission {
    height: 50px;
    width: 150px;
}
span#html5-qrcode-anchor-scan-type-change {
    padding: 15px;
}
button#html5-qrcode-button-file-selection {
    height: 50px;
    width: 200px;
}

/* Portrait */
@media only screen
and (min-device-width: 320px)
and (max-device-width: 568px)
and (-webkit-min-device-pixel-ratio: 2)
and (orientation: portrait) {
    
    body{
        overflow-x: hidden;
    }
    
    .autosize-div {
        width: 90%;
        max-width: 100%;
        margin-top: 5%;
        padding-bottom: 15px;
    }
    .k-form .k-form-md{
        max-width: 90%;
    }
    
    .k-form-fieldset {
        max-width:100%;
        padding: 10px; 
        font-size: 0.75rem;
        overflow-x: hidden;
    }
    .k-form-field{
        max-width: 375px;
        overflow-x: hidden;
    }

    .k-form-legend {
        font-size: 0.75rem; 
    }

    .k-form-label {
        font-size: 0.9rem; 
    }

    .k-input {
        font-size: 0.5rem; 
        padding: 5px; 
    }

    .k-textarea {
        font-size: 0.5rem; 
        padding: 5px; 
    }

    .k-form-button {
        font-size: 0.5rem;
        padding: 10px 20px; 
        max-width: 375px;
        overflow-x: hidden;
    }
    .k-form-upload{
        font-size: 0.2rem;
        overflow: hidden;
    }
    .k-upload-dropzone{
        font-size: 0;
    }
    .k-form-field-wrap{
        overflow: hidden;
    }
    .k-file-name{
        overflow: hidden;
    }
    .k-file-single{
        max-width: 325px;
        overflow: hidden
    }
    .k-upload{
        max-width: 375px;
        overflow: hidden;
    }
    textarea{
        resize: vertical;
    }
    .k-upload-files .k-reset{
        max-height:100px;
        overflow-y: hidden;
    }
    .k-progressbar{
        display: none;
    }
    .k-progress-status{
        display:none;
    }
    
   
   
}
