.error-box {
    border: 2px solid #ff0000; /* Red border */
    color: #ff0000; /* Red font color */
    padding: 10px; /* Padding to create some space inside the box */
    margin: 10px 0; /* Margin to separate it from other elements */
    text-align: left;
}

/* Optional: Add a box-shadow for a more visually appealing effect */
.error-box {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
}